<template>
    <div>
        <GetContent v-on:contentGetted="feedReady" v-bind:dataFeed="'smi24'"></GetContent>
        <b-button variant="danger" size="lg" class="rounded-circle d-flex justify-content-center align-items-center p-3 position-fixed" @click="directPush">
            <div style="font-size:2rem">
                <b-icon icon="bell"></b-icon>
            </div>
        </b-button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BButton } from 'bootstrap-vue'
import utils from '@/functions/functions.js'
export default {
    components: {
        BButton
    },
    data(){
        return {
            data: null
        }
    },
    computed: {
        getTo: function(){
            const matches = document.location.search.match(/to=([^&]+)/i)
            return true
        }
    },
    methods: {
        feedReady: function(arr){
            console.log('feedReady')
            const shuffled = utils.shuffleArray(arr)
            const randomItem = shuffled[0]
            this.data = {
                title: randomItem.title,
                // body: '',
                image: randomItem.img.replaceAll('100_100', '400_400'),
                icon: randomItem.img,
                url: '/transfer',
            }
            localStorage.setItem('transferTo', randomItem.url)
            console.log('randomItem', randomItem)
        },
        directPush: function(){
            console.log('directPush')
            const data = this.data
            const noticeObj = new Notification(data.title, data).addEventListener("click", (event) => {
                window.open(data.url)
            });
        }
    },
    created: function(){
        /*
        const vueContext = this
        window.addEventListener('beforeunload', function (e) {
            e.preventDefault();
            e.returnValue = ''; 
            vueContext.directPush()
            // return "You have attempted to leave this page. Are you sure?"
            // e.returnValue = '';
        });
        */
    },
    watch: {
        data: function(newVal, oldVal){
            if (newVal && !oldVal) this.directPush()
        }
    }
}
</script>

<style scoped lang="sass">
button
    top: 80px
    right: 64px
    z-index: 1000000
</style>