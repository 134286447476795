<template>
  <div id="app">
    <TechnicalProps></TechnicalProps>
    <NavLine></NavLine>

    <!-- с анимацией -->
    <transition name="fade-delayed">
      <router-view/>
    </transition>

    <FooterLine v-if="!isRotator"></FooterLine>    
    <OfflineIndicator></OfflineIndicator>

  </div>
</template>

<script>
import utils from '@/functions/functions'
import OfflineIndicator from './components/dev/OfflineIndicator.vue'
import NavLine from './components/general/NavLine'
import FooterLine from './components/general/FooterLine'
import TechnicalProps from './components/general/TechnicalProps.vue'

export default {
  components: {
    TechnicalProps,
    NavLine,
    FooterLine,
    OfflineIndicator
  },
  computed: {
    isRotator: function(){
      const vueContext = this
      return (utils.isRotator(vueContext))
    }
  },
  beforeCreate: function(){
    // navigator.serviceWorker.register(`/sw-first.js`, { scope:'/'})
    window.addEventListener(`yacounter96645984inited`, function(){
      console.log('metrika inited')
    })
  },
  created: function(){
    const vueContext = this
    window.addEventListener(`yacounter${utils.counterID(vueContext)}inited`, function(){
      console.log('metrika inited')
    })
  }
}
</script>

<style lang="sass">
body
  background: white
nav
  padding: 30px
  a
    font-weight: bold
    color: #2c3e50

    &.router-link-exact-active
      color: #42b983

.fade-delayed-leave-active
  transition: opacity .2s
.fade-delayed-enter-active 
  transition: opacity 0.15s ease-in 0.2s
.fade-delayed-enter, .fade-delayed-leave-to
  opacity: 0

.fade-leave-active
  transition: opacity .2s
.fade-enter-active 
  transition: opacity 0.15s ease-in
.fade-enter, .fade-leave-to
  opacity: 0  

.fade-append-leave-active
  transition: opacity .2s
.fade-append-enter-active 
  transition: opacity 0.15s ease-in
.fade-append-enter //, .fade-delayed-leave-to
  opacity: 0

</style>
