<template>
    <div></div>
</template>

<script>
import utils from '@/functions/functions'
import Bowser from "bowser"
import { mapGetters, mapActions } from 'vuex'
export default {
    data(){
        return {
            devChanged: false
        }
    },
    computed: {
        ...mapGetters([
            //fraudDetect
            'IF_FRAUD', 
            // devices
            'BROWSER', 'OS', 'PLATFORM', 'DEVICE_AUTH', 'FOCUS',
            //screen
            'SCREEN_SIZE', 'STANDALONE',
            //fullscreenState
            'FULLSCREEN_MODE',
            //flow
            'INSTALLABILITY'
        ]),
    },
    methods: {
        ...mapActions([
            //fraudDetect
            'FRAUD_DETECT', 
            //devices
            'SET_BROWSER', 'SET_OS', 'SET_PLATFORM', 'DEVICE_AUTHENTICITY_UPDATE', 'FOCUS_UPDATE',
            //screen
            'SCREEN_CHECK', 'STANDALONE_CHECK',
            //fullscreenState
            'FULLSCREEN_CHANGE',
            //flow
            'INSTALLABILITY_CHECK'
        ]),
        deviceCheck: function(){
            const vueContext = this
            const deviceItems = Bowser.parse(window.navigator.userAgent)
            const browserName = deviceItems.browser.name
            const osName = deviceItems.os.name
            const platformType = deviceItems.platform.type
            this.SET_BROWSER(browserName)
            this.SET_OS(osName)
            this.SET_PLATFORM(platformType)
            window.setTimeout(function(){
                const deviceItems = Bowser.parse(window.navigator.userAgent)
                const browserName = deviceItems.browser.name
                const osName = deviceItems.os.name
                const platformType = deviceItems.platform.type                
                vueContext.SET_BROWSER(browserName)
                vueContext.SET_OS(osName)
                vueContext.SET_PLATFORM(platformType)
            }, 100)
        },
    },
    created: function(){
        const vueContext = this
        this.deviceCheck()
        this.SCREEN_CHECK()
        this.INSTALLABILITY_CHECK()
        this.STANDALONE_CHECK()
        window.addEventListener('resize', function(){
            vueContext.SCREEN_CHECK()
            vueContext.deviceCheck()
            vueContext.STANDALONE_CHECK()
        })
        document.addEventListener("fullscreenchange", (function() {
            const fullscreenAtcive = !!document.fullscreenElement
            vueContext.FULLSCREEN_CHANGE(fullscreenAtcive)
        }))
        document.addEventListener("mozfullscreenchange", (function() {
            const fullscreenAtcive = !!document.fullscreenElement
            vueContext.FULLSCREEN_CHANGE(fullscreenAtcive)
        }))
        document.addEventListener("webkitfullscreenchange", (function() {
            const fullscreenAtcive = !!document.fullscreenElement
            vueContext.FULLSCREEN_CHANGE(fullscreenAtcive)
        }))
        document.addEventListener("msfullscreenchange", (function() {
            const fullscreenAtcive = !!document.fullscreenElement
            vueContext.FULLSCREEN_CHANGE(fullscreenAtcive)
        }))
        document.addEventListener('visibilitychange', function(){
            vueContext.FOCUS_UPDATE()
        })        
    },
    watch: {
        FULLSCREEN_MODE: function(){
            if (this.FULLSCREEN_MODE){
                const vueContext = this
                utils.safeGoal('fullscreen', vueContext)
            }
        },
        BROWSER: function(newVal, oldVal){
            this.devChanged = (!!oldVal && (newVal != oldVal)) ? true : this.devChanged
        },
        OS: function(newVal, oldVal){
            this.devChanged = (!!oldVal && (newVal != oldVal)) ? true : this.devChanged
        },
        PLATFORM: function(newVal, oldVal){
            this.devChanged = (!!oldVal && (newVal != oldVal)) ? true : this.devChanged
        },  
        devChanged: function(){
            this.DEVICE_AUTHENTICITY_UPDATE()
        },
    }
}
</script>