export default {
    state: {
        isFraud: undefined
    },
    actions: {
        FRAUD_DETECT(context, val){
            context.commit('FRAUD_STATE_UPDATE', val)
        }
    },
    mutations: {
        FRAUD_STATE_UPDATE(state, val){
            state.isFraud = val
        }
    },
    getters: {
        IF_FRAUD(state){
            return state.isFraud
        }
    }
}