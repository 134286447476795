// const scrollHeightCookieMatch = document.cookie.match(/scrollY=([\d]+)/i)
const initialDfState = (localStorage.getItem('dfFired')) ? true : false
export default {
    state: {
        ready: false,
        time: undefined,
        subscribe: undefined, //false
        installAbility: undefined,
        pushPromptActive: false,
        dfFired: initialDfState,
    },
    actions: {
        DF_UPDATE_STATE(context){
            localStorage.setItem('dfFired', 1)
            context.commit('DF_UPDATE')
        },
        PUSH_PROMPT_FIRE(context){
            context.commit('PUSH_PROMPT_STATE_CHANGE', true)
        },
        PUSH_PROMPT_UNFIRE(context){
            context.commit('PUSH_PROMPT_STATE_CHANGE', false)
        },        
        INSTALLABILITY_CHECK(context){
            context.commit('INSTALLABILITY_UPDATE')
        },
        FLOW_READY_CHECK(context, value){
            context.commit('FLOW_READY_UPDATE', value)
        },
        SESSION_START(context){
            const timeSec = parseInt(new Date().getTime())
            context.commit('TIME_UPDATE', timeSec)
        },
        PROMPT_ACCEPT(context){
            localStorage.setItem('subscribe', 1)
            context.commit('SUBSCRIBE_UPDATE', true)
        },
        PRPOMPT_DECLINE(context){
            context.commit('SUBSCRIBE_UPDATE', false)
        },
        SUBSCRIBE_CHECK(context){
            context.commit('SUBSCRIBE_UPDATE')
        }
    },
    mutations: {
        DF_UPDATE(state){
            state.dfFired = true
        },
        PUSH_PROMPT_STATE_CHANGE(state, value){
            state.pushPromptActive = value
        },
        INSTALLABILITY_UPDATE(state){
            state.installAbility = (window.BeforeInstallPromptEvent) ? true : false
        },
        FLOW_READY_UPDATE(state, value){
            state.ready = value
        },
        TIME_UPDATE(state, time){
            state.time = time
        },
        SUBSCRIBE_UPDATE(state, subscribe){
            // const subscribe = (localStorage.getItem('subscribe')==='1') ? true : state.subscribe
            state.subscribe = subscribe
        }        
    },
    getters: {
        DF_FIRED(state){
            return state.dfFired
        },
        PUSH_PROMPT_ACTIVE(state){
            return state.pushPromptActive
        },
        INSTALLABILITY(state){
            return state.installAbility
        },
        FLOW_READY(state){
            return state.ready
        },
        START_TIME(state){
            return state.time
        },
        SUBSCRIBE(state){
            return state.subscribe
        }
    }
}