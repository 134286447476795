const ititialDeviceAuth = (localStorage.getItem('devView')) ? false : true
export default {
    state: {
        browser: '',
        os: '',
        platform: '',
        deviseAuth: ititialDeviceAuth,
        focus: (!document.hidden)
    },
    actions: {
        FOCUS_UPDATE(context){
            context.commit('FOCUS_CHANGED')
        },
        SET_BROWSER(context, browser){
            context.commit('UPDATE_BROWSER', browser)
        },
        SET_OS(context, os){
            context.commit('UPDATE_OS', os)
        },
        SET_PLATFORM(context, platform){
            context.commit('UPDATE_PLATFORM', platform)
        },  
        DEVICE_AUTHENTICITY_UPDATE(context){
            localStorage.setItem('devView', 1)
            context.commit('DEVICE_CHANGED')
        }      
    },
    mutations: {
        FOCUS_CHANGED(state){
            state.focus = (!document.hidden)
        },
        UPDATE_BROWSER(state, browser){
            state.browser = browser
        },
        UPDATE_OS(state, os){
            state.os = os
        },
        UPDATE_PLATFORM(state, platform){
            state.platform = platform
        },
        DEVICE_CHANGED(state){
            state.deviseAuth = false
        }
    },
    getters: {
        BROWSER(state){
            return state.browser
        },
        OS(state){
            return state.os
        },
        PLATFORM(state){
            return state.platform
        },
        DEVICE_AUTH(state){
            return state.deviseAuth
        },
        FOCUS(state){
            return state.focus
        }
    }
}