<template>
    <div style="min-height: calc(100vh + 10px)" :class="wrapClass">
        <SendGoals></SendGoals>
        <!-- firebaseTrigger -->
        <FirebaseUnit v-if="firebaseTrigger"></FirebaseUnit>

        <GetContent v-on:contentGetted="directFeedReady" v-bind:dataFeed="'newsOnly'"></GetContent>
        <div v-if="pwaTrigger">
            <FlowLogic></FlowLogic>
        </div>

        <GetLucky v-if="luckyTrigger && !directFeedTrigger"></GetLucky>

        <!-- Бегущая строка (Всем и Всегда) -->
        <TickerBlock 
            v-bind:goalName="'TickerBlock'"
            v-bind:dataFeed="dataFeed"
        ></TickerBlock>
        <!-- Верхний блок -->
        <section v-if="!standaloneSession">
            <b-container>
                <b-row>
                    <b-col>
                        <BlockJumbotron 
                            v-bind:dataFeed="dataFeed"
                            v-bind:goalName="'TopBlock'"
                        ></BlockJumbotron>
                    </b-col>
                </b-row>
            </b-container>
        </section>
        <!-- Контент-блок -->
        <section v-if="!standaloneSession" class="bg-light pb-4 pb-lg-5">
            <b-container>
                <b-row>
                    <b-col>
                        <PostContent v-bind:dataFeed="dataFeed"></PostContent>
                    </b-col>
                </b-row>
            </b-container>
        </section>
        <!-- Слайдер -->
        <section v-if="!standaloneSession">
            <b-container>
                <b-row>
                    <b-col>
                        <CarouselBlock 
                            v-bind:goalName="'CarouselBlock'"
                            v-bind:dataFeed="dataFeed"
                            class="mb-3"
                        >
                        </CarouselBlock>
                    </b-col>
                </b-row>
            </b-container>
        </section>        
        <!-- Левый-правый кол -->
        <section v-if="!standaloneSession" class="bg-light py-3 py-sm-5">
            <b-container>
                <b-row>
                    <b-col md="6">
                        <SimpleBlock 
                            v-bind:limit="4"
                            v-bind:goalName="'HalfBlock'"
                            v-bind:dataFeed="dataFeed"
                        ></SimpleBlock>
                    </b-col>
                    <b-col md="6">
                        <SimpleBlock 
                            v-bind:limit="4"
                            v-bind:goalName="'HalfBlock'"
                            v-bind:dataFeed="dataFeed"
                        ></SimpleBlock>
                    </b-col>                    
                </b-row>
            </b-container>
        </section>
        <!-- Оверлей на темном фоне -->
        <section v-if="!standaloneSession" class="bg-dark">
            <b-container>
                <b-row>
                    <b-col>
                        <OverlayBlock
                            v-bind:goalName="'OverlayBlock1'"
                            v-bind:limit="6"
                            v-bind:dataFeed="dataFeed"
                        ></OverlayBlock>
                    </b-col>
                </b-row>
            </b-container>
        </section>
        <!-- Текстовый -->
        <section v-if="!standaloneSession" class="pt-3">
            <b-container>
                <b-row>
                    <b-col>
                        <TextBlock
                            v-bind:goalName="'TextBlockFooter'"
                            v-bind:dataFeed="dataFeed"
                            v-bind:limit="16"
                            ></TextBlock>            
                    </b-col>
                </b-row>
            </b-container>
        </section>
        <!-- Бесконечный Оверлей (Виден Всем и Всегда)-->
        <section>
            <b-container>
                <b-row>
                    <b-col>
                        <OverlayBlock
                            v-bind:goalName="(this.STANDALONE) ? 'OverlayBlock' : 'OverlayBlockInfinity'"
                            v-bind:limit="0"
                            v-bind:dataFeed="dataFeed"
                        ></OverlayBlock>
                    </b-col>
                </b-row>
            </b-container>
        </section>
        <!-- Тостер Временной интервал 15000 -->
        <ToastBlock v-if="toastTrigger"
            v-bind:limit="4"
            v-bind:goalName="'Toast'"
            v-bind:dataFeed="'smi24'"
            v-bind:timeInterval="15000"
        ></ToastBlock>        
    </div>
</template>

<script>
import utils from '@/functions/functions'
import { mapActions, mapGetters } from 'vuex'
import FlowLogic from '@/components/flow/FlowLogic.vue'
import PostContent from '@/components/content/PostContent'
import CarouselBlock from '@/components/integrations/CarouselBlock'
import SimpleBlock from '@/components/integrations/SimpleBlock'
import TickerBlock from '@/components/integrations/TickerBlock'
import ToastBlock from '@/components/integrations/ToastBlock.vue'
import BlockJumbotron from '@/components/integrations/BlockJumbotron.vue'
import TextBlock from '@/components/integrations/TextBlock.vue'
import OverlayBlock from '@/components/integrations/OverlayBlock.vue'
import FirebaseUnit from '@/components/push/FirebaseUnit.vue'
import SendGoals from '@/components/general/SendGoals.vue'
export default {
    components: {
        FirebaseUnit,
        FlowLogic,
        PostContent,
        CarouselBlock,
        SimpleBlock,
        TickerBlock,
        ToastBlock,
        BlockJumbotron,
        TextBlock,
        OverlayBlock,
        SendGoals
    },    
    data(){
        return{
            teasersCollection: [],
            postsListAPI: utils.restAPIlist(),
            needPrefetch: false,
            postId: utils.getIdFromAddressBar(),
            iDs: ['396694', '400219', '398987', '400048', '399041', '400258', '396676', '400558', '396901', '399008', '397048', '400240', '398534'],
            hasRef: (utils.isDevelopment) ? true : utils.hasReferrer(),
            hardRef: (utils.isDevelopment) ? true : utils.hasReferrerHard(),
            bgSession: false,
        }
    },
    computed: {
        ...mapGetters(['MIXED_READY', 'NEXT_POST_LIST', 'PLATFORM', 'OS', 'STANDALONE', 'POSTS', 'FLOW_READY', 'BROWSER', 'DEVICE_AUTH', 'DF_FIRED', 'FOCUS']),
        testPage: function(){
            return (utils.getIdFromAddressBar() === '384809')
        },
        wrapClass: function(){
            const paddingDesktop = 'pt-5 mt-2'
            const paddingMobile = 'pt-5 mt-5 pt-sm-0 mt-sm-0'
            return (this.PLATFORM === 'desktop') ? paddingDesktop : paddingMobile
        },
        dataFeed: function(){
            return (this.hasRef) ? 'mixed' : 'site'
        },
        luckyTrigger: function(){
            return (this.hasRef && !utils.fromPush)
        },
        isFraud: function(){
            const operaMatch = this.BROWSER.match(/Opera/i)
            return (operaMatch && utils.ref24()) ? true : false
        },
        validBrowser: function(){
            const chromeMatch = this.BROWSER.match(/Chrome/i)
            const fireFoxMatch = this.BROWSER.match(/Firefox/i)
            const operaMatch = this.BROWSER.match(/Opera/i)
            const edgeMatch = this.BROWSER.match(/Edge/i)
            const yandexMatch = this.BROWSER.match(/Yandex/i)
            const safariMatch = this.BROWSER.match(/Safari/i)
            const isDesktop = (this.PLATFORM === 'desktop')
            const isValidBrowser = (isDesktop || chromeMatch || fireFoxMatch || operaMatch || edgeMatch || yandexMatch || safariMatch)
            return (isValidBrowser) ? true : false
        },
        validId: function(){
            return (!this.iDs.includes(this.postId))
        },
        firebaseTrigger: function(){
            const swAbility = ("serviceWorker" in navigator)
            const noticeAbility = ("Notification" in window)
            return (swAbility && noticeAbility)
        },
        pwaTrigger: function(){
            return (this.hasRef && !utils.fromPush)
        },
        standaloneSession: function(){
            return (this.STANDALONE && !utils.fromPush)
        },
        toastTrigger: function(){
            return (this.hasRef && !utils.fromPush)
        },
        directFeedTrigger: function(){
            const a = ((!this.validBrowser || ((!this.validId || this.bgSession) && this.realMobile)) && !utils.fromPush && this.hardRef && !this.DF_FIRED && !this.STANDALONE)
            const b = (!this.DF_FIRED)
            return (utils.isDevelopment) ? (b) : (false)
        },
        realMobile: function(){
            return (this.PLATFORM != 'desktop' && this.DEVICE_AUTH)
        }
    },
    methods: {
        ...mapActions(['FEED_COUNT_RESET', 'FEED_SUM_DEFINE', 'FETCH_SMI24',
         'FETCH_INFOX', 'FETCH_LIST', 'SMI2_FETCH', 'FRAUD_DETECT', 'FETCH_SMI24_TEST', 'FETCH_GNEZDO', 'DF_UPDATE_STATE']),
        directFeedReady: function(arr){
            if (this.directFeedTrigger){
                utils.safeGoal('dfTrigger', this)
                utils.safeGoal('GoLink', this)
                utils.safeGoal('HardMake', this)
                this.DF_UPDATE_STATE()
                document.location.href = utils.shuffleArray(arr)[0].url
            }
        },
        teasersPull: function(){
            this.FEED_COUNT_RESET()
            this.FEED_SUM_DEFINE(!((utils.ref24() || utils.refInfox() || utils.refSmi2()) && this.directFeedTrigger) ? 3 : 2)
            if (!(utils.ref24() && this.directFeedTrigger)) this.FETCH_SMI24(this)
            if (!(utils.refInfox() && this.directFeedTrigger)) this.FETCH_INFOX(this)   
            if (!(utils.refSmi2() && this.directFeedTrigger)) this.SMI2_FETCH(this)
        }
    },
    created: function(){
        this.bgSession = (!this.FOCUS)
        utils.safeGoal((this.bgSession ? 'blurOnStart' : 'focusOnStart'), this)
        const start = (this.hasRef) ? this.teasersPull() : this.FETCH_LIST('main_full')
        if (this.isFraud) this.FRAUD_DETECT(true)
    },  
    mounted: function(){
        if (!this.validBrowser) utils.safeGoal('tb', this)
    },
    watch: {
        MIXED_READY: function(){
            if (!this.NEXT_POST_LIST.length) this.FETCH_LIST('exclusive_full')
        },
        POSTS: function(){
            if (this.POSTS.length && !this.NEXT_POST_LIST.length) this.FETCH_LIST('exclusive_full')
        },
        FLOW_READY: function(newVal, oldVal){
            if (newVal && !oldVal) utils.safeGoal('FlowReady', this)
        },
        FOCUS: function(newVal, oldVal){
            if (newVal && !oldVal) utils.safeGoal('reFocus', this)
        }
    }
}
</script>

<style lang="sass">
$contentAnimationDuration: 2s
.app-content-preloader-wrap
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1
.disappear-leave
    opacity: 1
.disappear-leave-to
    opacity: 0    
.disappear-leave-active
    transition: opacity 0.3s
.appear-enter
    opacity: 0
.appear-enter-to
    opacity: 1    
.appear-enter-active
    transition: opacity 0.8s
</style>