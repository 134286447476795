<template>
    <div>
         <ScreenModal v-bind:modalReady="modalReady" v-bind:delayTime="0" v-bind:targetEvent="'push'"></ScreenModal>
        <!-- <pushOnline></pushOnline> -->
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { initializeApp } from "firebase/app"
import { getMessaging, getToken } from "firebase/messaging"
// import { getAnalytics } from "firebase/analytics"
import utils from '../../functions/functions.js'
import pushOnline from "./pushOnline.vue"
export default{
    components: {
        pushOnline
    },
    data(){
        return {
            backendActive: undefined,
            firebaseApp: undefined,
            getTokenStarted: undefined,
            permissionStatus: Notification.permission, // 'default' by default
            modalReady: false //по сути алиас локалТриггера через задержку (без нее не отрабатывает цель в СкринМодале)
        }
    },
    computed: {
        ...mapGetters(['FIREBASE_CONFIG', 'FIREBASE_TOKEN', 'FIREBASE_USERID', 'PUSH_PROMPT_ACTIVE', 'OS', 'BROWSER']),
        testPage: function(){
            return (utils.getIdFromAddressBar() === '384809')
        },
        lockerTrigger: function(){
            const isValidBrowser = (!this.BROWSER.match(/Firefox|Yandex/i))
            const hasRef = (!utils.isDevelopment) ? utils.hasReferrerHard() : true
            const isWindows = (this.OS === 'Windows' && isValidBrowser)
            return (this.PUSH_PROMPT_ACTIVE && hasRef && isWindows)
        }
    },
    methods: {
        ...mapActions(['TOKEN_SET', 'PUSH_PROMPT_FIRE', 'PUSH_PROMPT_UNFIRE']),
        backendPing: async function(){
            try {
                const ok = (await fetch(`${utils.backendHost}/observe`, {
                method: 'GET',
                headers: {
                    'Content-type' : 'application/json'
                },
                })).ok
                if (ok) this.backendActive = true
            }catch(e){
                // console.log('backend brocken')
            }
        },
        sendToken: async function(token){
            if (this.FIREBASE_TOKEN === token) return false //ЗАКОММЕНЧЕНО ТОЛЬКО ДЛЯ ДЕБАГА
            const res = await fetch(`${utils.backendHost}/addToken`, {
                method: 'POST',
                headers: {
                    'Content-type' : 'application/json'
                },
                body: JSON.stringify({token: token}),
            })
            const result = await res.json()
            if (result.success && result.subscribed){
                const userData = {
                    userId: result.userId,
                    token: token
                }
                this.TOKEN_SET(userData)
            }
        },
        saveToken: async function(token){
            await fetch(`${utils.backendHost}/saveToken`, {
                method: 'POST',
                headers: {
                    'Content-type' : 'application/json'
                },
                body: JSON.stringify({ token: token }),
            })
        },
    },
    beforeCreate: function(){
        navigator.serviceWorker.register("/firebase-messaging-sw.js", {scope: "/firebase-cloud-messaging-push-scope"}); //.then((reg) => {})
    },
    created: async function(){
        this.backendPing()
        navigator.permissions.query({ name: "notifications" })
        .then((permissionStatus) => {
            permissionStatus.onchange = () => {
                this.permissionStatus = permissionStatus.state
            };
        });
    },
    watch: {
        backendActive: function(){
            this.firebaseApp = initializeApp(this.FIREBASE_CONFIG)
            const messaging = getMessaging(this.firebaseApp)
            // const analytics = getAnalytics(app)
            this.getTokenStarted = getToken(messaging, {vapidKey: "BGDBm44OnbP2bulOrUtnl8yeA_RZu7uE0EHOYUsW1pGvaiEHQetzvCuJB32yKLGr6Pzvibgma6fZi9hT3q92X94"}).then(
                (token) => {
                    if (token){
                        // console.log('token getted, nothing to do', token)
                        if (!utils.isDevelopment) this.sendToken(token)
                        // if (this.testPage) this.saveToken(token)
                    }
                }
            ).catch((e)=>{
                this.permissionStatus = 'denied'
            })
        },
        firebaseApp: function(){
            if (!this.FIREBASE_USERID) utils.safeGoal('firebaseInit', this)
        },
        FIREBASE_TOKEN: function(newVal, oldVal){
            if (!!newVal && !oldVal) utils.safeGoal('firebaseSubscribe', this)      
        },
        getTokenStarted: function(){
            if (this.permissionStatus === 'default') this.PUSH_PROMPT_FIRE()
        },
        permissionStatus: function(){
            this.PUSH_PROMPT_UNFIRE()
        },
        lockerTrigger: function(newVal, oldVal){
            if (newVal && !oldVal){
                const vueContext = this
                window.setTimeout(function(){
                    vueContext.modalReady = true
                }, 100)
            }else{
                this.modalReady = false
            }
        }
    }
}
</script>