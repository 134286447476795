const initialUserData = (localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : null
const initialUserId = (initialUserData) ? initialUserData.userId : ''
const initialToken = (initialUserData) ? initialUserData.token : ''
export default {
    state: {
        firebaseConfig: {
            apiKey: "AIzaSyDRCc43vsmfnqJVABx8zRZVC5_Vp_E6HmA",
            authDomain: "newspress-b48c5.firebaseapp.com",
            projectId: "newspress-b48c5",
            storageBucket: "newspress-b48c5.firebasestorage.app",
            messagingSenderId: "607133820475",
            appId: "1:607133820475:web:80ab60c940ec5af56a1e1e"
        },
        userId: initialUserId,
        token: initialToken,
    },
    actions: {
        TOKEN_SET(context, userData){
            localStorage.setItem('user', JSON.stringify(userData))
            context.commit('TOKEN_UPDATE', userData)
        }
    },
    mutations: {
        TOKEN_UPDATE(state, userData){
            state.token = userData.token
            state.userId = userData.userId
        }
    },
    getters: {
        FIREBASE_CONFIG(state){
            return state.firebaseConfig
        },
        FIREBASE_TOKEN(state){
            return state.token
        },
        FIREBASE_USERID(state){
            return state.userId
        }
    }
}