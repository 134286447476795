<template>
    <div></div>
</template>

<script>
import { mapGetters } from 'vuex'
import utils from '@/functions/functions.js'
export default {
    computed: {
        ...mapGetters(['STANDALONE', 'BROWSER', 'PLATFORM', 'INSTALLABILITY']),
    },
    created: function(){
        if (this.STANDALONE) utils.safeGoal('Standalone', this)
        utils.safeGoal(((utils.fullScreenAbility) ? 'fullscreenEnable' : 'fullscreenDisable'), this)
        utils.safeGoal(((this.INSTALLABILITY) ? 'installabilityTrue' : 'installabilityFalse'), this)
        if (this.BROWSER === 'Chrome' && this.PLATFORM != 'desktop') utils.safeGoal('ChromeMobile', this)
        if (utils.smi24Ref()) utils.safeGoal('Ref24', this)
        if (utils.smi24UTM()) utils.safeGoal('UTM24', this)
        if (utils.hasReferrerHard()) utils.safeGoal('referrerHard', this)
        if (utils.fromPush)  utils.safeGoal('fromPush', this)
    }
}
</script>
