<template>
  <div :class="wrapClass">
    <!-- слайдер -->
    <section v-if="!hasRef" class="mb-4 mt-0">
      <b-container>
        <b-row>
          <b-col>
            <CarouselBlock
              v-bind:limit="12"
              v-bind:goalName="goalName"
              v-bind:dataFeed="feedCarousel"
              v-bind:heighControl="true"
            ></CarouselBlock>    
          </b-col>
        </b-row>
      </b-container>
    </section>
    <!-- половинчатый блок -->
    <section v-if="!hasRef" class="bg-light pb-2">
      <b-container class="pt-3 pt-md-4">
        <b-row class="py-lg-4">
          <b-col class="col-12 col-lg-6">
            <SimpleBlock 
              v-bind:infinity="false"
              v-bind:autoCount="false"
              v-bind:limit="3"
              v-bind:goalName="goalName"
              v-bind:dataFeed="feedSimple"
          ></SimpleBlock>
          </b-col>
          <b-col class="col-12 col-lg-6">
            <SimpleBlock 
              v-bind:infinity="false"
              v-bind:autoCount="false"              
              v-bind:limit="3"
              v-bind:goalName="goalName"
              v-bind:dataFeed="feedSimple"
            ></SimpleBlock>
          </b-col>        
        </b-row>
      </b-container>
    </section>
    <!-- Текстовый под реф -->
    <section v-if="hasRef" class="pt-3">
        <b-container>
            <b-row>
                <b-col>
                    <TextBlock
                        v-bind:goalName="'ClickFromHome'"
                        v-bind:dataFeed="'mixed'"
                        v-bind:limit="60"
                        ></TextBlock>            
                </b-col>
            </b-row>
        </b-container>
    </section>
    <!-- блок с оверлеями -->
    <section class="pt-2">
      <b-container>
        <b-row>
          <b-col>
            <OverlayBlock 
              v-bind:dataFeed="feedOverlay"
              v-bind:limit="limit"
              v-bind:goalName="'ClickFromHome'"
            ></OverlayBlock>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <!-- Тостер -->
    <ToastBlock
      v-if="hasRef"
      v-bind:limit="4"
      v-bind:goalName="'Toast'"
      v-bind:timeInterval="15000"
    ></ToastBlock>
  </div>
</template>

<script>
import utils from '../functions/functions'
import { mapActions, mapGetters } from 'vuex'
import NewsBlock from '../components/content/NewsBlock'
import CarouselBlock from '../components/integrations/CarouselBlock'
import SimpleBlock from '../components/integrations/SimpleBlock'
import OverlayBlock from '../components/integrations/OverlayBlock'
import ToastBlock from '../components/integrations/ToastBlock.vue'
import TextBlock from '@/components/integrations/TextBlock.vue'

export default {
  components: {
        CarouselBlock,
        NewsBlock,
        SimpleBlock,
        OverlayBlock,
        ToastBlock,
        TextBlock
    },   
  data(){
    return {
      needPrefetch: false,
      hasRef: (utils.isDevelopment) ? false : utils.hasReferrer()
    }
  },
  computed: {
    ...mapGetters(['POSTS', 'PREFETCHED_CONTENT', 'PREFETCHED_FULL', 'LAST_ROUTE', 'POSTS', 'PLATFORM']),
    feedCarousel: function(){
      return (this.hasRef) ? 'mixed' : 'site'
    },
    feedSimple: function(){
      return (this.hasRef) ? 'mixed' : 'site'
    },
    feedOverlay: function(){
      return (this.hasRef) ? 'mixed' : 'site'
    },
    goalName: function(){
      return (this.hasRef) ? 'ClickFromHome' : null
    },
    limit: function(){
      return (this.hasRef) ? 0 : 30
    },
    wrapClass: function(){
        const paddingDesktop = 'pt-5 mt-2'
        const paddingMobile = 'pt-5 mt-5 pt-sm-0 mt-sm-0'
        return (this.PLATFORM === 'desktop') ? paddingDesktop : paddingMobile
    }    
  },
  methods: {
    ...mapActions(['FETCH_LIST', 'FETCH_SMI24', 'FETCH_INFOX', 'RESET_ALL', 'SMI2_FETCH', 'FEED_COUNT_RESET', 'FEED_SUM_DEFINE']),
    teasersPull: function(){
      this.FEED_COUNT_RESET()
      this.FEED_SUM_DEFINE(3)
      this.FETCH_SMI24(this)
      this.FETCH_INFOX(this)   
      this.SMI2_FETCH(this)
    }
  },
  created: function(){
    if (this.hasRef){
      this.teasersPull()
    }else{
      this.FETCH_LIST('main_full')
    }
  },
}
</script>
