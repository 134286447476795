<template>
    <div>
        <section>
            <ScreenModal
                v-bind:modalReady="modalReady" 
                v-bind:delayTime="delayTime"
                v-bind:targetEvent="targetEvent"
            ></ScreenModal>
            <!-- Блюрное затемнение не слетает при фокусе-расфокусе -->
            <ScreenOverlay v-if="desktopOverlay"></ScreenOverlay>            
        </section>        
    </div>
</template>

<script>
import utils from '../../functions/functions'
import { mapActions, mapGetters } from 'vuex'
// import ScreenModal from './ScreenModal'
import ScreenOverlay from './ScreenOverlay'

export default {
    data(){
        return {
            beforeInstall: false, //маркер того, что инсталлер был показан
            flowReady: false, //маркер общей готовности
            desktopOverlay: false,
            pushPromptUnfired: undefined //pushPrompt всплыл и сейчас его нет            
        }
    },
    components: {
        // ScreenModal,
        ScreenOverlay,
    },
    computed: {
        ...mapGetters(['START_TIME', 'OS', 'PUSH_PROMPT_ACTIVE', 'INSTALLABILITY']),
        modalReady: function(){
            const hasRef = (utils.isDevelopment) ? true : utils.hasReferrer()
            const notStandalone = !window.matchMedia('(display-mode: standalone)').matches
            const pwaConditions = (this.beforeInstall && notStandalone)
            const fullscreenConditions = (utils.fullScreenAbility() && hasRef)
            const resultConditions = (this.INSTALLABILITY) ? pwaConditions : fullscreenConditions
            return (resultConditions && !this.PUSH_PROMPT_ACTIVE) ? true : false
        },
        delayTime: function(){
            const timeOut = (utils.isDevelopment) ? 5000 : 30000 //5 секунд для дева и 30 для прода
            return (this.pushPromptUnfired) ? timeOut : 0 //НЕ ПОНЯТНО ЗАЧЕМ ЭТО БЫЛО this.OS === 'Windows' && - СКОРЕЕ ВСЕГО ЭТО НЕ НУЖНО
        },
        targetEvent: function(){
            return (this.INSTALLABILITY) ? 'pwa' : 'fullscreen'
        }
    },
    created: function(){
        const vueContext = this
        window.addEventListener('focus', function(){
            vueContext.desktopUnBlur()
        })
    },
    beforeMount: function(){
        let vueContext = this
        let deferredPrompt;
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault()
            deferredPrompt = e
            this.flowReady = true
            this.beforeInstall = (!this.beforeInstall) ? true : this.beforeInstall
            //навешиваем обработчик на модалку и кнопку
            const triggerElements = document.getElementsByClassName('app-install-trigger')
            for (let i=0; i<triggerElements.length; i++){
                const installTrigger = triggerElements[i]
                installTrigger.addEventListener('click', (e) => {
                    //превентим дефолт
                    e.preventDefault()
                    try {
                        vueContext.desktopBlur() // десктопное затемнение
                        deferredPrompt.prompt(); //показываем Инсталлер
                        utils.safeGoal('PromptShow', vueContext)
                        //ждем результат                
                        deferredPrompt.userChoice.then((choiceResult) => {
                            vueContext.desktopUnBlur() //убираем десктопное затемнение
                            if (choiceResult.outcome === 'accepted') {
                                //фиксируем во vuex
                                vueContext.SUBSCRIBE_SET()
                                utils.safeGoal('PromptAccept', vueContext)
                            }else{
                                // если НЕ подписка
                                //сабмитим цель 
                                utils.safeGoal('PromptDecline', vueContext)
                            }
                            //грохаем deferredPrompt
                            deferredPrompt = null;
                        });
                    } catch(e){
                        //Логирование ошибок реализуем на своей стороне
                        utils.safeGoal('ErrorInLogic', vueContext)
                    }
                })
            }
        })        
    },
    methods: {
        ...mapActions(['FLOW_READY_CHECK', 'SUBSCRIBE_SET']),
        desktopBlur: function(){
            if (this.OS === 'Windows'){
                this.desktopOverlay = true
                const htmlParentEl = document.getElementsByTagName('html')[0]
                htmlParentEl.style.filter = 'blur(1px)'
            }
        },
        desktopUnBlur: function(){
            this.desktopOverlay = false
            const htmlParentEl = document.getElementsByTagName('html')[0]
            htmlParentEl.removeAttribute('style')
        }        
    },
    watch: {
        flowReady: function(newVal, oldVal){
            const ready = (newVal && !oldVal)
            this.FLOW_READY_CHECK(ready)
        },
        PUSH_PROMPT_ACTIVE: function(newVal, oldVal){
            if (!newVal && oldVal){
                this.pushPromptUnfired = true    
                // console.log('PUSH_PROMPT_ACTIVE вспыхнул и погас')
            }
        }
    }
}
</script>