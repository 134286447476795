export default {
    state: {
        focus: false,
    },
    actions: {
        FOCUS_BACK(context){
            context.commit('FOCUS_RENEW')
        }
    },
    mutations: {
        FOCUS_RENEW(state){
            state.focuseBack = true
        }
    },
    getters: {
        USER_COMEBACK(state){
            return state.userComeback
        }
    }
}