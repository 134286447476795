<template>
    <div>TransferView</div>
</template>

<script>
import utils from '../functions/functions.js'
export default {
    created: function(){
        const url = localStorage.getItem('transferTo')
        localStorage.removeItem('transferTo')
        console.log('url', url)
        document.location.href = url
    }
}
</script>
